/* --------------------------------------------------------------
    Import CCS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #6e6d6d;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  // p {
  // 	margin: 0 0 5px 0;
  // }
  a {
    color: #6e6d6d;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #footer-info {
    color: #6e6d6d;
  }
}
.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.12) 0 1px 5px;
}
.box-shadow {
  box-shadow:
    rgba(0, 0, 0, 0.12) 0 1px 5px,
    rgba(0, 0, 0, 0.24) 0 1px 4px;
}

.font-weight-strong {
  font-weight: 600;
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-primary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 75%;
    max-width: 650px;
    img {
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.help-center {
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: darken($theme-primary, 7.5%);
      }
    }
  }
}
#banner-wrap {
  &.home {
    background: transparent url(../images/banner-wrap-bg.jpg) no-repeat 0 bottom/cover;
  }
  &.inner {
    background: url(../images/banner-wrap-bg-inner.jpg) repeat-x top center;
  }
  #banner {
    h1 {
      color: $theme-secondary;
    }
  }
}
.cta-wrap {
  background:$theme-primary;
  color: #fff;
  .cta-btn {
    width: 220px;
  }
}
.asc-logo {
  min-width: 85px;
}
.dropdown-list.cta {
  width: 230px;
  .dropdown {
    background: #e0512f;
    color: #fff;
    border: 0;
    font-family: 'Fjalla One', Impact, Verdana;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 25px;
    padding: 10px 10px 8px 20px;
    height: inherit;
    &:hover {
      background: #d64320;
    }
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo {
    img {
      display: block;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      margin-right: 15px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 16px;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    #banner {
      &.couple {
        background: transparent url(../images/banner-couple.png) no-repeat 5% 100%;
        background-size: 25%;
      }
      &.older-couple {
        background: transparent url(../images/banner-older-couple.png) no-repeat 0% 100%;
        background-size: 34%;
      }
    }
  }
}
